@import "src/assets/scss/var";

.contact {
  .title {
    font-weight: 300;
    font-size: 32px;
    color: $title;
    margin-bottom: 14px;
  }
}

// Responsive
@media (max-width: 575px) {
  .contact {
    .title {
      margin-top: 30px;
      font-size: 20px;
      margin-bottom: -14px;
    }
  }
}