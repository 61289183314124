@import "src/assets/scss/var";

.single-post {
  .mobile-post-head {
    display: none;
  }

  .thumb {
    width: 100%;
    background-color: $white;
    padding: 15px;
    border-radius: 20px;

    img {
      border-radius: 20px;
    }
  }

  .audio {
    width: 100%;
    height: 100%;
    background-color: $white;
    padding: 15px;
    border-radius: 20px;

    iframe {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
}

.post-header {
  margin-top: 40px;
  border-radius: 20px;
  padding: 24px 64px;
  background-color: $main-dark;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .info {
    display: flex;
    align-items: center;
    .date {
      display: inline-block;
      text-align: center;
      font-size: 24px;
      font-weight: 200;
      background-color: $secondary;
      color: $main-dark;
      padding: 18px 24px;
      border-radius: 20px;

      span {
        font-size: 32px;
      }

      hr {
        height: 3px;
        opacity: 1;
        background-color: $main-dark;
        margin: 6px 0;
      }
    }

    h3 {
      margin-right: 32px;
      color: #fff;
    }
  }

  .actions {
    position: relative;
    display: inherit;

    .share-methods {
      position: absolute;
      background-color: $main-darker;
      top: -3px;
      padding: 10px 11px;
      border-radius: 20px;
      right: -156px;

      a {
        padding: 0;
        width: 30px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
        margin: 0 5px;
        background-color: #3a4760;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
          margin: 0;
        }
      }
    }

    a {
      font-size: 16px;
      padding: 10px 27px;
      font-weight: 200;
      text-decoration: none;
      border-radius: 17px;

      &.share {
        background-color: $white;
        color: $title;
        margin-left: 13px;
      }

      &.print {
        background-color: $main;
        color: #fff;
      }

      svg {
        margin-right: 10px;
      }
    }
  }
}

.content {
  background-color: $white;
  box-shadow: -1px 10px 49px rgba(0, 0, 0, 0.06);
  padding: 50px;
  border-radius: 20px;
  margin-top: 30px;
  color: $paragraph;

  p,
  ul,
  ol {
    margin-bottom: 24px;
    color: $paragraph;
    line-height: 30px;
  }

  blockquote {
    color: #5e5e5e;
    border-right: 4px solid $main;
    background-color: #f9f9f9;
    padding: 24px;
    border-radius: 10px;
    font-style: italic;
  }
}

.loading-spinner {
  text-align: center;
  font-size: 35px;
  color: $main;
}

.comments {
  margin-top: 64px;

  .title {
    font-size: 24px;
    font-weight: 300;
    color: $title;
  }

  ul {
    padding: 0;

    li {
      list-style: none;
      margin-top: 24px;

      ol {
        padding-left: 0;
      }

      .comment {
        background-color: $white;
        box-shadow: -1px 10px 49px rgba(0, 0, 0, 0.06);
        border-radius: 20px;
        padding: 25px 40px;
        display: flex;

        img {
          margin-left: 23px;
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }

        h3 {
          margin-bottom: 15px;
          color: $title;
        }

        p {
          margin-bottom: 0;
          color: $paragraph;
        }
      }
    }
  }
}

// Dark Mode
.dark-mode {
  .post-header {
    .actions {
      a {
        &.share {
          background-color: $bg;
        }
      }
    }
  }
}

// Responsive
@media (min-width: 575px) and (max-width: 768px) {
  .single-post {
    .thumb {
      margin-bottom: 30px;
    }

    .post-header {
      display: block;

      .info {
        margin-bottom: 30px;

        h3 {
          line-height: 40px;
          margin-bottom: 0;
        }
      }
      
      .actions {
        display: flex;

        .share-methods {
          right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .dark-mode {
    .single-post {
      background-color: $bg;
    }
  }

  .single-post {
    background-color: $white;
    margin-bottom: -34px;
    padding-bottom: 34px;

    .mobile-post-head {
      border-top: 2px solid #D0D0D0;
      display: block;
      padding: 20px 16px;

      .wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .date {
          font-weight: 200;
          color: $title-main;
          font-size: 14px;

          svg {
            margin-left: 7px;
          }
        }

        .go-back {
          font-size: 14px;
          color: $paragraph;
          cursor: pointer;

          svg {
            margin-right: 7px;
          }
        }
      }

      h3 {
        color: $title;
        font-size: 18px;
        margin-bottom: 0;
      }
    }

    .thumb {
      padding: 0;

      img {
        border-radius: 0;
      }
    }

    .audio,
    .post-header {
      display: none;
    }

    .content {
      padding: 0;
      box-shadow: unset;
      background-color: unset;

      p,
      ul,
      ol {
        font-size: 14px;
      }
    }

    .comments {
      .title {
        font-size: 20px;
      }

      ul {
        li {
          .comment {
            padding: 25px;

            img {
              width: 63px;
              height: 63px;
            }

            h3 {
              font-size: 16px;
            }

            p {
              font-size: 12px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
