@import "src/assets/scss/var";

.player {
  position: fixed;
  bottom: -230px;
  width: 94%;
  background-color: $white;
  padding: 15px;
  border-radius: 20px;
  box-shadow: -1px 10px 49px rgba(0, 0, 0, 0.06);
  left: 50%;
  transform: translateX(-50%);
  transition: bottom 0.3s;
  z-index: 9;

  .close {
    position: absolute;
    right: -17px;
    top: -18px;
    background-color: $main;
    color: #fff;
    padding: 5px 11px;
    font-size: 17px;
    border-radius: 11px;
    cursor: pointer;
  }

  iframe {
    border-radius: 10px;
  }

  &.opened {
    bottom: 20px;
  }

  @media only screen and (max-width: 575px) {
    &.opened {
      bottom: 80px;
    }

    .close {
      right: 0px;
      top: -43px;
    }
  }
}
