@import "src/assets/scss/var";

.top-bar {
  background: $white;
  min-height: 100px;
  margin-bottom: 64px;

  .row {
    min-height: 100px;
  }

  img {
    height: 40px;
    image-rendering: -webkit-optimize-contrast;
  }

  .search {
    position: relative;

    input {
      height: 48px;
      padding: 0 20px;
      width: 100%;
      border: none;
      background: $bg;
      font-weight: 100;
      border-radius: 10px;
      outline: none;
      color: #454545;

      &::placeholder {
        color: $paragraph;
      }
    }

    button {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      width: 48px;
      border: none;
      border-radius: 10px;
      color: #fff;
      background: $main-dark;
    }
  }

  .nav-items {
    li {
      a {
        text-decoration: none;
        font-family: "Cairo";
        font-weight: 200;
        color: $title;
      }
    }
  }

  ul {
    direction: ltr;
    margin-bottom: 0;
    padding: 0;

    li {
      margin-right: 24px !important;
    }
  }

  .toggle-dark-mode {
    width: 69px;
    height: 34px;
    background: $bg;
    border-radius: 8px;
    position: relative;
    cursor: pointer;

    .toggle-btn {
      color: #fff;
      background: $main-dark;
      position: absolute;
      left: 4px;
      font-size: 14px;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 4px;
      border-radius: 8px;
      transition: $transition;
    }

    &.dark {
      .toggle-btn {
        left: 39px;
        color: $secondary;
      }
    }
  }

  .mobile-search {
    display: none;
  }
}

// Dark Mode
.dark-mode {
  .top-bar {
    .search {
      button {
        background-color: #617291;
      }
    }
  }
}

// Responsive
@media (min-width: 992px) and (max-width: 1400px) {
  .nav-items {
    li {
      margin-right: 13px !important;

      a {
        font-size: 13px;
      }
    }
  }

  .search-col {
    width: 36.666667%;
  }

  .menu-col {
    width: 38.333333%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .top-bar {
    .logo-col {
      width: 20%;

      img {
        height: 30px;
      }
    }

    .search-col {
      width: 36.666667%;
    }

    .menu-col {
      width: 43.333333%;

      .nav-items {
        li {
          margin-right: 13px !important;

          a {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (min-width: 575px) and (max-width: 768px) {
  .top-bar {
    padding: 30px 0;

    .logo {
      margin: 0 auto 20px;
      display: table;
    }

    ul,
    .toggle-dark-mode {
      margin-top: 35px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .top-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
    min-height: 70px;
    margin-bottom: 0;

    .row {
      min-height: 70px;
    }

    ul, .search {
      display: none;
    }

    img {
      height: 32px;
    }

    .mobile-search {
      display: block;

      .open-search {
        font-size: 22px;
        margin-left: 20px;
        color: $title;
        cursor: pointer;
      }

      .search {
        position: absolute;
        width: 100%;
        right: 0;
        z-index: 99;
        top: 70px;
        padding: 16px;
        background-color: $white;

        &.open {
          display: block;
        }

        button {
          height: 48px;
          top: 16px;
          left: 16px;
        }
      }
    }
  }
}