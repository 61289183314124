@import "src/assets/scss/var";

.download-book {
  border-radius: 23px;
  position: relative;
  background-color: $main-darker;
  margin-bottom: 64px;

  .squares {
    position: absolute;
    left: 40px;
    top: 40px;
    width: 120px;
  }

  .halfCircle {
    position: absolute;
    left: 170px;
    bottom: 0;
    width: 300px;
  }

  .book {
    position: absolute;
    left: 70px;
    bottom: 0;
    width: 340px;
  }

  .text {
    margin: 61px;
    display: inline-block;

    h1 {
      color: #fff;
      line-height: 70px;
    }

    a {
      margin-top: 100px;
      display: inline-block;
      font-size: 17px;
      font-weight: 200;
      color: #fff;
      text-decoration: none;
      background-color: $main;
      padding: 16px 40px;
      border-radius: 14px;
      transition: $transition;

      svg {
        margin-right: 11px;
      }

      &:hover {
        background-color: $main-dark;
      }
    }
  }
}

.first-post {
  background-color: $white;
  display: flex;
  padding: 24px;
  border-radius: 20px;
  align-items: center;
  box-shadow: -1px 10px 49px rgba(0, 0, 0, 0.06);

  img {
    border-radius: 20px;
    height: 320px;
    margin-left: 32px;
  }

  .text {
    .mobile-wrapper {
      img {
        display: none;
      }
    }

    h3 {
      color: $title;
      margin-bottom: 15px;
    }

    .date {
      font-weight: 200;
      color: $title-main;

      svg {
        margin-left: 7px;
      }
    }

    p {
      margin-top: 24px;
      color: $paragraph;
      line-height: 30px;
    }
  }
}

.listen-sc {
  height: 100%;
  background-color: #ff7700;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;

  .icon {
    svg {
      font-size: 90px;
    }
  }

  p {
    font-size: 22px;
    font-weight: 300;
    padding: 0 25px;
    margin: 30px 0 40px;
    line-height: 44px;
  }

  a {
    width: 205px;
    border-radius: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 20px;
    transition: $transition;
    color: #ff7700;
    background-color: #fff;
    margin: 0 auto;

    &:hover {
      background-color: #d86500;
      color: #fff;
    }
  }
}

.pagination {
  margin-top: 64px;
  justify-content: center;
  direction: rtl;
  padding: 0;

  li {
    &.selected {
      a {
        background-color: $secondary;
        color: $main-dark !important;
      }
    }

    a {
      color: #fff !important;
      text-decoration: none;
      width: 50px;
      height: 50px;
      margin: 0 5px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 300;
      font-size: 17px;
      background-color: $main-dark;
    }
  }
}

// Dark Mode
.dark-mode {
  .download-book {
    background-color: #222b3b;
  }
}

// Responsive
@media (min-width: 992px) and (max-width: 1400px) {
  .listen-sc {
    p {
      margin: 15px 0 20px;
      line-height: 30px;
      font-size: 18px;
    }

    a {
      width: 169px;
      font-size: 14px;
    }
  }

  .first-post {
    img {
      height: 228px;
      display: none;
    }

    .btns {
      .btn {
        width: 185px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .download-book {
    .squares {
      left: 25px;
      width: 70px;
      top: 25px;
    }

    .halfCircle {
      width: 110px;
      left: 145px;
    }

    .book {
      width: 187px;
      left: 36px;
    }

    .text {
      margin: 30px;

      h1 {
        line-height: 60px;
        font-size: 34px;
      }

      a {
        margin-top: 10px;
        font-size: 16px;
        padding: 12px 28px;
      }
    }
  }

  .first-post {
    display: block;
    padding: 18px;

    img {
      margin-left: 0;
      height: auto;
      margin-bottom: 20px;
    }
  }
}

@media (min-width: 575px) and (max-width: 768px) {
  .download-book {
    .squares {
      left: 25px;
      width: 70px;
      top: 64px;
    }

    .halfCircle {
      width: 110px;
      left: 75px;
    }

    .book {
      width: 128px;
      left: 36px;
    }

    .text {
      margin: 30px;

      h1 {
        line-height: 40px;
        font-size: 24px;
      }

      a {
        margin-top: 10px;
        font-size: 13px;
        padding: 12px 28px;
      }
    }
  }

  .first-post {
    display: block;
    margin-bottom: 30px;
    padding: 18px;

    img {
      height: auto;
      margin-bottom: 20px;
      margin-left: 0;
    }
  }

  .listen-sc {
    padding: 40px 0;
  }
}

@media only screen and (max-width: 575px) {
  .listen-sc {
    display: none;
  }

  .first-post {
    display: block;
    padding: 13px;

    img {
      height: auto;
      margin-left: 0;
      display: none;
    }

    h3 {
      font-size: 18px;
    }

    .date {
      font-size: 14px;
    }

    p {
      display: none;
    }

    .mobile-wrapper {
      display: flex;
      align-items: center;

      img {
        display: block !important;
        width: 100px;
        border-radius: 15px;
        margin-left: 10px;
      }
    }
  }

  .download-book {
    border-radius: 0;
    margin-bottom: 30px;

    .squares {
      left: 25px;
      width: 70px;
      top: 64px;
    }

    .halfCircle {
      width: 110px;
      left: 75px;
    }

    .book {
      width: 128px;
      left: 36px;
    }

    .text {
      margin: 30px;

      h1 {
        color: #fff;
        line-height: 34px;
        font-size: 18px;
        width: 80%;
      }

      a {
        margin-top: 10px;
        font-size: 13px;
        padding: 12px 28px;
      }
    }
  }

  .pagination li a {
    width: 35px;
    height: 35px;
    font-size: 13px;
  }
}
