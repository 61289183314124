@import "src/assets/scss/var";

footer {
  margin-top: 64px;
  background-color: $main-dark;
  padding: 75px 0;

  .widget {
    h3 {
      color: #fff;
      margin-bottom: 44px;
      font-size: 22px;
    }

    &.about {
      img {
        height: 35px;
        margin-bottom: 35px;
        image-rendering: -webkit-optimize-contrast;
      }

      p {
        color: #fff;
        opacity: 0.74;
        font-size: 14px;
        line-height: 26px;
      }
    }

    &.newsletter {
      form {
        width: 100%;
        position: relative;

        input {
          width: 100%;
          font-weight: 100;
          padding: 18px 32px;
          border-radius: 16px;
          border: none;
          outline: $main;
        }

        button {
          border-radius: 16px;
          color: #fff;
          font-size: 25px;
          border: none;
          background-color: $main;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 60px;
        }
      }

      span {
        display: block;
        text-align: center;
        margin-top: 30px;
        color: $secondary;
        font-size: 14px;
      }
    }

    &.contacts {
      ul {
        list-style: none;
        padding: 0;
        display: flex;

        li {
          margin-left: 8px;
          a {
            width: 47px;
            height: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #fff;
            font-size: 18px;
            background-color: #464e60;
            transition: $transition;

            &:hover {
              background-color: $main-darker;
            }
          }
        }
      }
    }

    ol {
      list-style: none;
      padding: 0;
      margin-top: 25px;

      li {
        color: $secondary;
        margin-bottom: 12px;
        a {
          color: #fff;
          text-decoration: none;
          margin-right: 12px;
        }
      }
    }
  }

  .copyrights {
    margin-top: 50px;
    text-align: center;
    font-size: 14px;
    color: #fff;
  }
}

// Responsive
@media (min-width: 768px) and (max-width: 992px) {
  footer {
    .widget {
      h3 {
        font-size: 16px;
      }

      &.about {
        p {
          font-size: 12px;
          line-height: 22px;
        }
      }

      &.newsletter {
        form {
          input {
            font-size: 12px;
          }
        }

        span {
          font-size: 12px;
        }
      }

      ol {
        li {
          font-size: 14px;
        }
      }
    }
  }
}

@media (min-width: 575px) and (max-width: 768px) {
  footer {
    .widget {
      margin-bottom: 50px;
    }
  }
}

@media (max-width: 575px) {
  footer {
    display: none;
  }
}
