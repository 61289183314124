@import "./var";
@media print {
  * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  footer,
  .top-bar ul,
  .top-bar .toggle-dark-mode,
  .search,
  .comments,
  .single-post .audio,
  .single-post .post-header .actions {
    display: none;
  }

  .content {
    background-color: #fff !important;
    box-shadow: none !important;
    page-break-inside: avoid !important;
  }

  .post-header .info .date {
    background-color: #f3f3f3;
  }

  .content p, .content ul, .content ol {
    color: #454545 !important;
  }

  .single-post .thumb {
    border: 1px solid $main-dark;
  }

  .player {
    display: none;
  }
}
