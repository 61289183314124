@import "src/assets/scss/var";

.post-card {
  background-color: $white;
  margin-top: 30px;
  padding: 18px;
  border-radius: 20px;
  align-items: center;
  box-shadow: -1px 10px 49px rgba(0, 0, 0, 0.06);

  img {
    border-radius: 20px;
    margin-bottom: 20px;
  }

  .text {
    h3 {
      color: $title;
      margin-bottom: 15px;
    }

    .date {
      font-weight: 200;
      color: $title-main;

      svg {
        margin-left: 7px;
      }
    }

    p {
      margin-top: 24px;
      color: $paragraph;
      line-height: 30px;
    }
  }
}

// Responsive
@media (max-width: 575px) {
  .post-card {
    padding: 13px;
    h3 {
      font-size: 18px;
    }

    .date {
      font-size: 14px;
    }

    p {
      display: none;
    }

    .mobile-wrapper {
      display: flex;
      align-items: center;

      img {
        display: block !important;
        width: 100px;
        border-radius: 15px;
        margin-left: 10px;
        margin-bottom: 0;
      }
    }
  }
}