@import "src/assets/scss/var";

.about {
  .about-card {
    background-color: $white;
    box-shadow: -1px 10px 49px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 16px;

    .myPP {
      img {
        border-radius: 16px;
      }
    }

    .about-content {
      h3 {
        font-weight: 300;
        font-size: 32px;
        color: $title;
        margin-bottom: 14px;
      }

      span {
        color: $title-main;
        font-weight: 200;
        display: block;
        margin-bottom: 14px;
      }

      p {
        color: $paragraph;
        font-size: 14px;
        font-weight: 100;
        line-height: 29px;
        margin-bottom: 0;
      }
    }
  }

  .social-links {
    margin-top: 48px;

    h3 {
      color: $title;
      font-size: 24px;
      font-weight: 300;
    }

    .links {
      margin-top: 32px;
      background-color: $white;
      box-shadow: -1px 10px 49px rgba(0, 0, 0, 0.06);
      border-radius: 20px;
      padding: 30px 30px 0;

      a {
        text-decoration: none;
        font-weight: 300;
        width: 100%;
        display: block;
        text-align: center;
        padding: 15px 0;
        border-radius: 20px;
        margin-bottom: 30px;

        &.soundcloud {
          color: #FF7700;
          background-color: rgba(255, 119, 0, 0.16);
        }

        &.behance {
          color: #053EFF;
          background-color: rgba(5, 62, 255, 0.16);
        }

        &.dribbble {
          color: #EA4C89;
          background-color: rgba(234, 76, 137, 0.16);
        }

        &.twitter {
          color: #1DA1F2;
          background-color: rgba(29, 161, 242, 0.16);
        }

        &.facebook {
          color: #4267B2;
          background-color: rgba(66, 103, 178, 0.16);
        }

        &.email {
          color: #2C374B;
          background-color: rgba(44, 55, 75, 0.16);
        }

        &.whatsapp {
          color: #25D366;
          background-color: rgba(37, 211, 102, 0.16);
        }

        &.website {
          color: #593CE4;
          background-color: rgba(89, 60, 228, 0.16);
        }

        svg {
          margin-right: 10px;
          font-size: 20px;
        }
      }
    }
  }
}

// Dark Mode
.dark-mode {
  .about {
    .social-links {
      .links {
        a {
          color: #fff;

          &.soundcloud {
            background-color: rgba(255, 119, 0, 0.58);
          }

          &.behance {
            background-color: rgba(5, 62, 255, 0.58);
          }

          &.dribbble {
            background-color: rgba(234, 76, 137, 0.58);
          }

          &.twitter {
            background-color: rgba(29, 161, 242, 0.58);
          }

          &.facebook {
            background-color: rgba(66, 103, 178, 0.58);
          }

          &.email {
            background-color: rgba(44, 55, 75, 0.58);
          }

          &.whatsapp {
            background-color: rgba(37, 211, 102, 0.58);
          }

          &.website {
            background-color: rgba(89, 60, 228, 0.58);
          }
        }
      }
    }
  }
}

// Responsive
@media (min-width: 575px) and (max-width: 768px) {
  .about {
    .about-card {
      .myPP {
        img {
          margin-bottom: 30px;
        }
      }
    }

    .social-links {
      .links {
        a, svg {
          font-size: 15px !important;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .about {
    .about-card {
      margin-top: 30px;

      .myPP {
        img {
          margin-bottom: 30px;
        }
      }

      .about-content {
        h3 {
          font-size: 24px !important;
        }

        span {
          font-size: 17px;
        }
      }
    }

    .social-links {
      .links {
        padding: 17px 17px 0 !important;

        a {
          margin-bottom: 17px;
          font-size: 13px;

          svg {
            margin-right: 4px;
            font-size: 13px;
          }
        }
      }
    }
  }
}