@import "src/assets/scss/var";

.navigation-bar {
  display: none;
}

// Responsive
@media only screen and (max-width: 575px) {
  .navigation-bar {
    z-index: 999999;
    width: 100vw;
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    padding: 10px 0;
    box-shadow: 0 -10px 49px rgba(0, 0, 0, 0.06);

    a {
      display: block;
      text-align: center;
      color: $title;
      text-decoration: none;
      font-weight: 200;
      font-size: 13px;

      &.active {
        color: $title-main;
      }

      span {
        margin-top: 5px;
        display: block;
      }

      svg {
        font-size: 18px;
      }
    }
  }
}